import React from 'react'
import Form from './Signinform'
import './signin.css'

export default function Signup() {
    return (
        <div className="signin-box">
            <Form />
        </div>
    )
}
 