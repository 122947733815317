import React from "react";
import "animate.css/animate.min.css";
import { Grid } from "@material-ui/core";

export default function terms() {
    return (
        <div>
            <div className="background-dots-one" style={{ width: '70%' }} />
            <Grid
                container
                style={{ padding: "30px", paddingTop: "80px", paddingBottom: "80px" }}
            >
                <Grid item md={12} style={{ paddingTop: "40px" }}>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "80px",
                            alignItems: "center",
                            width: "60vw",
                            backgroundColor: "transparent",
                            paddingBottom: "0px"
                        }}
                    >
                        <div style={{ zIndex: "999" }}>
                            <p className="heading">
                                Privacy Policy
                            </p>
                        </div>
                    </div>
                </Grid>


                <Grid item md={12} style={{ padding: "0px" }}>
                    <div style={{ padding: '120px 0px 0px ', width: '40vw' }}>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Thank you for considering a privacy policy for your digital signal platform.
                            A privacy policy outlines how personal data is collected, used, and protected by your platform.
                            Below are some of the key contents that should be included in your privacy policy:</p>
                    </div>


                    <div style={{ padding: '20px 0px', width: '40vw' }}>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Overview: Start with an overview of what your platform does and what personal data you collect.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Types of personal data collected: List the types of personal data you collect from users, such as names, email addresses, and payment information.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            How personal data is collected: Explain how you collect personal data, such as through registration forms, cookies, or user-generated content.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>

                            Purpose of collecting personal data: Clearly state why you collect personal data. Is it to provide services to users or for marketing purposes?
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>

                            Use of personal data: Explain how you use personal data and who has access to it. For example, do you share personal data with third-party service providers or advertisers?
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>

                            Retention and deletion of personal data: Describe how long you keep personal data and how it is deleted. Also, explain the process users can follow to request the deletion of their personal data.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Security measures: Detail the security measures you take to protect personal data, such as encryption or secure servers.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            User rights: Explain the rights users have over their personal data, such as the right to access, rectify, or erase their personal data.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>

                            Children’s privacy: If your platform is intended for children, explain how you comply with the Children’s Online Privacy Protection Act (COPPA) and other relevant laws.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Updates to the privacy policy: Explain how and when you will update your privacy policy, and how users will be notified.
                        </p>
                    </div>

                    <div style={{ padding: '40px 0px 0px ', width: '40vw' }}>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                        Remember that your privacy policy should be clear, concise, and easy to understand.
                         Make sure it is prominently displayed on your platform and easily accessible to users.
                            </p>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}
