import React from "react";
import {
  fade,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Arrow from "@material-ui/icons/ArrowForward";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #c1c1c1",
    fontSize: 16,
    color: "#191A1B",
    width: "250px",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1)
  },
  headerLogo: {
    width: "50px"
  }
}));

export default function CustomizedInputs() {
  const classes = useStyles();

  return (
    <div>
      <Grid container className='signin-form-padding' >
        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding:'70px 0px 20px 0px',
            
          }}
        >
          <p className="subheading">Sign in</p>
        </Grid>

        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
            padding:'5px 0px'
          }}
        >
            <FormControl      >
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{
                  color: "#191A1B",
                  fontSize: "18px",
                  fontFamily: "Open Sans"
                }}
              >
                Username
              </InputLabel>
              <BootstrapInput  id="bootstrap-input" />
            </FormControl>
    
        </Grid>

        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
            padding:'5px 0px'
          }}
        >
     
       
            <FormControl >
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{
                  color: "#191A1B",
                  fontSize: "18px",
                  fontFamily: "Open Sans"
                }}
              >
                Password
              </InputLabel>
              <BootstrapInput  id="bootstrap-input" />
            </FormControl>
       
        </Grid>

        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding:'25px 0px'
  
          }}
        >
          <button
            className="button-primary-small"
   
          >
            Sign in
            <Arrow style={{ marginBottom: "-4px",marginLeft:'4px', fontSize: "18px" }} />
          </button>
        </Grid>

        <Grid
                item
                sm={12}
                style={{
                 padding:'20px 60px'
                }}
              >
             
               <Grid container> 
               <Grid item sm={6}
                 style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"}}
               >  
          <Link to='/signup' style={{textDecoration:'none'}}>
                
                <p
                style={{
                  color: "#2181F7",
                  fontSize: "11px",
                  fontFamily: "Open Sans"
                }}
                >
                 Don't have an account ?
                </p>
                </Link>
                </Grid>
                <Grid item sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"}}
                >      

                <Link to='/reset' style={{textDecoration:'none'}}>
                < p style={{
                  color: "#2181F7",
                  fontSize: "11px",
                  fontFamily: "Open Sans",
                  fontWeight:'400'
                }}>
                  Forgot Password ?
                </p>
                </Link>
                </Grid>
                </Grid>
              </Grid>

          


      </Grid>
    </div>
  );
}
