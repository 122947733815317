import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden"

import Background from "../../../assets/customer.png";
// import { Image,Button } from "react-bootstrap";

export default class extends Component {
  render() {
    return (
      <Grid container style={{ paddingTop: "150px", paddingBottom: "50px",  }}>
        <Grid item md={12} lg={12} sm={12}> 
          <div
            style={{
              display: "flex",
              padding: "40px",
              alignItems: "center",
              justifyContent: "center",
              // width:'60vw',
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
            }}
          >
            <div style={{ textAlign: "center"}}>
              <p className="heading">Start Engaging Customers</p>
              <p className="subheading">
                Experience the future of customer engagement
              </p>
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={12} lg={12} sm={12} xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
          }}
        >
         
            <butoon className="button-primary">See Our Use Cases</butoon>
        
        </Grid>

        <Grid item md={12}>
          <Grid container>
          <Hidden smDown>      <Grid item
          md={7} lg={7} 
            >
              <div
                style={{
                  width: "60vw",
                  height: "60vh",
                  backgroundImage: `url(${Background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: " 47% 20%",
                  borderRadius: "30px"
                }}
              />
            </Grid>
            <Grid item md={5}>
              <div
                style={{
                  paddingRight: "120px",
                  display: "flex",
                  alignItems: "center",
                  height: "127px",
                  backgroundColor: "#ffffff",
                  backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
                }}
              />
            </Grid>
            </Hidden>

          </Grid>
        </Grid>
      </Grid>
    );
  }
}
