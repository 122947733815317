import React from "react";
import "./payment.css";
import Grid from "@material-ui/core/Grid";

export default function Trial() {
  return (
    <div
      className="payment-box-gradient "
      style={{
        height: "400px",
        justifyContent: "flex-start",
        padding: "0px 10px 0px 60px"
      }}
    >
      <Grid container>
        <Grid item md={12}>
          <div style={{ padding: "10px 0px" }}>
            <p
              className="card-title"
              style={{ color: "#fff", padding: "4px 0px" }}
            >
              Get Upto
            </p>
            <p className="card-heading" style={{ color: "#fff" }}>
              20% <br />
              Discount{" "}
            </p>
          </div>
        </Grid>

        <Grid item md={12}>
          <Grid container>
            <Grid item md={12} style={{}}>
              <div style={{ padding: "10px 0px", paddingRight: "20px" }}>
                <p className="card-body" style={{ color: "#fff" }}>
                  When you need to switch basic plans to premium with more than
                  100 screens you getting exiting discounts.
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <div
            className="card-link"
            style={{ padding: "10px 2px", color: "#fff" }}
          >
            Learn More
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
