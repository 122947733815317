import React from "react";
import "animate.css/animate.min.css";
import { Grid } from "@material-ui/core";

export default function privacy() {
    return (
        <div>
            <div className="background-dots-one" style={{ width: '70%' }} />
            <Grid
                container
                style={{ padding: "30px", paddingTop: "80px", paddingBottom: "80px" }}
            >
                <Grid item md={12} style={{ paddingTop: "40px" }}>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "80px",
                            alignItems: "center",
                            width: "60vw",
                            backgroundColor: "transparent",
                            paddingBottom: "0px"
                        }}
                    >
                        <div style={{ zIndex: "999" }}>
                            <p className="heading">
                                Terms and Conditions
                            </p>
                        </div>
                    </div>
                </Grid>


                <Grid item md={12} style={{ padding: "0px" }}>
                    <div style={{ padding: '120px 0px 0px ', width: '40vw' }}>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Thank you for considering terms and conditions for your digital signal platform. Terms and conditions outline the rules and regulations that govern the use of your platform. Below are some of the key contents that should be included in your terms and conditions:

                        </p>
                    </div>


                    <div style={{ padding: '20px 0px', width: '40vw' }}>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Overview: Start with an overview of what your platform does and what services it provides.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Acceptance of terms: Explain that by using your platform, users are agreeing to your terms and conditions.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            User obligations: Outline the obligations users have when using your platform, such as not violating any laws or infringing on any third-party rights.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Platform ownership: Clearly state that you own the platform and all its intellectual property, and that users have no ownership rights.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            User-generated content: Explain the rules for user-generated content, such as how it is moderated and who owns the intellectual property rights.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Payment and fees: If your platform charges fees, explain the payment process and any applicable fees or taxes.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Limitations of liability: Limit your liability for any damages arising from the use of your platform, except in cases of gross negligence or willful misconduct.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Dispute resolution: Detail the process for resolving disputes, such as through arbitration or mediation.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Termination: Explain the circumstances under which you can terminate a user's access to your platform, such as for violation of the terms and conditions.
                        </p>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Changes to the terms and conditions: Explain how and when you will update your terms and conditions, and how users will be notified.


                        </p>
                    </div>

                    <div style={{ padding: '40px 0px 0px ', width: '40vw' }}>
                        <p className="body" style={{ padding: '5px 0px', fontSize: '16px' }}>
                            Remember that your terms and conditions should be clear, concise, and easy to understand. Make sure they are prominently displayed on your platform and easily accessible to users.
                        </p>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}
