import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/icons/Menu";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // height: 430,
    zIndex: 100000000,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    backgroundColor: "red",
    height: "80px"
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: "999999999999",
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative"
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },

  title: {
    flexGrow: 1
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const [scroll, setScroll] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const listenScrollEvent = e => {
    if (window.scrollY > 72) {
      setScroll(false);
    } else {
      setScroll(true);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });

  // const drawer = (

  //   <div style={{ backgroundColor:'red'}}>
  //     <div className={classes.toolbar} />
  //     <Divider />
  //     <List>hi</List>
  //     <Divider />
  //     <List>hello</List>
  //   </div>
  // );

  return (
    <React.Fragment>
      <Hidden smDown>
        <AppBar
          style={{
            backgroundColor: scroll ? "transparent" : "#fff",
            minHeight: "65px",
            padding: scroll ? "0px" : "10px",
            paddingTop: scroll ? "70px" : "5px",
            boxShadow: scroll ? "none" : "0 2px 50px 0 rgba(0,0,0,0.3)",
            zIndex: "999999999999"
          }}
          position={scroll ? "static" : "sticky"}
        >
          <Container maxWidth="lg" style={{ padding: "0px" }}>
            <Toolbar style={{ padding: "0px", zIndex: "9999999" }}>
              <div className={classes.title} style={{ padding: "0px 10px" }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <img src={require("../../assets/logo.png")} />
                </Link>
              </div>

              <Link to="/" style={{ textDecoration: "none" }}>
                <p
                  className="header-menu-title"
                  style={{ color: scroll ? "#fff" : "#191A1B" }}
                >
                  Features
                </p>
              </Link>
              <Link to="/payment" style={{ textDecoration: "none" }}>
                <p
                  className="header-menu-title"
                  style={{ color: scroll ? "#fff" : "#191A1B" }}
                >
                  Pricing
                </p>
              </Link>
              <p
                className="header-menu-title"
                style={{ color: scroll ? "#fff" : "#191A1B" }}
              >
                Contact
              </p>
              <Link to="/signin" style={{ textDecoration: "none" }}>
                <p
                  className="header-menu-title"
                  style={{ color: scroll ? "#fff" : "#191A1B" }}
                >
                  Login
                </p>
              </Link>
            </Toolbar>
          </Container>
        </AppBar>
      </Hidden>

      <Hidden mdUp>
        <AppBar
          style={{
            backgroundColor: "#fff",
            padding: "5px 8px",
            boxShadow: "0 2px 50px 0 rgba(0,0,0,0.3)",
            zIndex: "9999999"
          }}
          position="sticky"
        >
          <Container maxWidth="lg" style={{ padding: "0px" }}>
            <Toolbar style={{ padding: "0px", zIndex: "9999999" }}>
              <div className={classes.title} style={{ padding: "0px 10px" }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <img src={require("../../assets/logo.png")} width="42px" />
                </Link>
              </div>
              <Menu
                style={{
                  color: "#FB625A",
                  marginRight: "5px",
                  fontSize: "30px"
                }}
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              />
            </Toolbar>
          </Container>
        </AppBar>

        <Drawer
          variant="temporary"
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          style={{ zIndex: "999999999" }}
        >
          <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
              {" "}
              <Link to="/" style={{ textDecoration: "none" }}>
                <p className="header-menu-title"> Features </p>
              </Link>
            </List>
            <Divider />
            <List>
              {" "}
              <Link to="/payment" style={{ textDecoration: "none" }}>
                <p className="header-menu-title">Pricing</p>
              </Link>
            </List>
            <Divider />
            <List>
              {" "}
              <Link to="" style={{ textDecoration: "none" }}>
                <p className="header-menu-title">Contact</p>
              </Link>
            </List>
            <Divider />
            <List>
              {" "}
              <Link to="/signin" style={{ textDecoration: "none" }}>
                <p className="header-menu-title">Terms and conditiony</p>
              </Link>
            </List>
            <List>
              {" "}
              <Link to="/signin" style={{ textDecoration: "none" }}>
                <p className="header-menu-title">Login</p>
              </Link>
            </List>
          </div>
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}
