import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Grid
      container
      style={{
        background: "linear-gradient(138.5deg, #FB625A 0%, #763EE7 100%)",
        padding: "100px",
        paddingTop:'0px'
      }}
    >
      <Grid
        item
        md={3}
        lg={3}
        sm={12}
        xs={12}
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          marginTop: "80px",
        }}
      >
        <div>
          <img src={require("../../assets/log-light.png")} width="70%" />

          <p className="list-item">© {(new Date().getFullYear())} All Rights Reserved</p>
        </div>
      </Grid>

      <Grid
        item
        md={2}
        lg={2}
        sm={12}
        xs={12}
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          marginTop: "80px",
        }}
      >
        <div>
          <p className="title" style={{ color: "#fff", marginBottom: "18px" }}>
            Product
          </p>
          <p className="list-item">Dashboard</p>
          <p className="list-item">Player App</p>
          <p className="list-item">Uses Cases</p>
          <p className="list-item">Help Center</p>
        </div>
      </Grid>

      <Grid
        item
        md={2}
        lg={2}
        sm={12}
        xs={12}
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          marginTop: "80px",
        }}
      >
        <div>
          <p className="title" style={{ color: "#fff", marginBottom: "18px" }}>
            COMPANY
          </p>
          <p className="list-item">Careers</p>
          <p className="list-item">Blog</p>
        </div>
      </Grid>

      <Grid
        item
        md={2}
        lg={2}
        sm={12}
        xs={12}
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          marginTop: "80px",
        }}
      >
        <div>
          <p className="title" style={{ color: "#fff", marginBottom: "18px" }}>
            LEGAL
          </p>
          <Link to="/privacy" style={{ textDecoration: "none" }}>
          <p className="list-item">Privacy Policy</p>
          </Link>
          <Link to="/terms" style={{ textDecoration: "none" }}>
          <p className="list-item">Terms & conditions</p>
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}
