import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import './upload.css'


export default function Upload() {

  const [upload, setUpload] = useState(true)
     


        return (
          <Grid container className='container' >
       

          <Grid item md={5} >
            <div
              style={{
                display: "flex",
                padding: "30px",
                alignItems: "center",
                backgroundColor: "#ffffff",
                width:'60vw',
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
              }}
            >
              <div>
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'    animateOnce={true} delay={200} >
                <p className="heading">
                Upload.
                </p>
                </ScrollAnimation>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                padding: "30px 30px",
                alignItems: "center",
                backgroundColor: "#ffffff"
              }}
            >
               <p className="title">
              Keep your desired content in<br />
           the cloud</p>
            </div>

            <div
              style={{
                display: "flex",
                padding: "30px",
                alignItems: "center",
                backgroundColor: "#ffffff",
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
                 
              }}
            >
         
              <p className="body">
              May it be videos or images upload the<br />
              content to be published on our servers.<br />
              <span className="link">Learn more</span>
                </p>
            </div>
          </Grid>
          <Grid item md={7} style={{zIndex:'9999', display:'flex', justifyContent:'flex-end', alignItems:'center', position: 'relative', }}>
          <div className='upload-image-position'>
          <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'   animateOnce={true} delay={300} 
             afterAnimatedIn={ () => setUpload(false)} >
            {
             upload ? 
           <img src={require("../../../assets/uploading.png")} width="100%"/>:
           <img src={require("../../../assets/upload-completed.png")} width="100%"/>
            }
           </ScrollAnimation>
          </div>

          <div className='list-image-position'>
          <ScrollAnimation animateIn='bounceInRight'
             animateOut='bounceOutLeft'
             animateOnce={true} 
             delay={500}>
              <img src={require("../../../assets/Type List.png")}  className='list-image'/>
           </ScrollAnimation>
          </div>

           <div>
           <img src={require("../../../assets/TVElement.png")} width="80%"/>
           </div>
          </Grid>
        </Grid>
        );
      }
