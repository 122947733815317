import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import '../../../App.css'
import './landing.css'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import Hidden from "@material-ui/core/Hidden";



export default class Landing extends Component {
  render() {
    const logoGrid = {
      height: "150px",
      width: "290px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    };

    return (
      <div>
        <Grid container  className="container">
          <Grid item md={6} style={{ paddingTop: "40px" }}>
            <div
             className='landing-heading-box'
            >
              <div style={{zIndex:'999'}}>
                <p className="title" style={{marginBottom:'40px'}}>
                  Start making more customer conversions using
                </p>
                <p className="heading">
                  a managed <br /> digital signage
                </p>
              </div>
            </div>

            <div
              style={{
               
              }}
             className='landing-signup-box'

            >
              <button className='button-primary'>Sign up free</button>
            </div>

            <div
            className='landing-trial-box'
            >
              <div>
              <p className="title">
                14 days free trial. 
              </p>
              <p className="body">No credit card required</p></div>
            </div>
          </Grid>
          <Grid item md={6} style={{zIndex:'9999', display:'flex', justifyContent:'flex-end', alignItems:'center', }}>
          <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'    animateOnce={true} delay={300} >
           <div>
           <img src={require("../../../assets/TVElement.png")} width="100%"/>
           </div>
           </ScrollAnimation>
          </Grid>
        </Grid>

        <Grid container className='landing-clients-container'>
          <Grid item md={12}>
          <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'    animateOnce={true} delay={300} >
            <Grid container>
              <Grid xs={6} md={3} style={logoGrid}>
                <img src={require("../../../assets/ksum_logo3 copy.png")} />
              </Grid>
              <Grid xs={6} md={3} style={logoGrid}>
                <img src={require("../../../assets/Innov8_logo copy.png")} />
              </Grid>
              <Grid xs={6} md={3} style={logoGrid}>
                <img src={require("../../../assets/a copy.png")} />
              </Grid>
              <Grid xs={6} md={3} style={logoGrid}>
                <img src={require("../../../assets/huddle.png")} />
              </Grid>
            </Grid>
            </ScrollAnimation>
          </Grid>
        </Grid>

          
      


      </div>
    );
  }
}
