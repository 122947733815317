import React from "react";
import "animate.css/animate.min.css";
import Landing from "./Landing";

export default function payment() {
    return (
            <div>

       <div  className="background-dots-one" style={{width:'70%'}} />
      {/* <div className="background-image" /> */}

               
                      <Landing />

            </div>
    )
}
