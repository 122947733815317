import React from 'react'
import Form from './Signupform'

export default function Signup() {
    return (
        <div className="signup-box" >
            <Form />
        </div>
    )
}
 