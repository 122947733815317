import React from "react";
import Landing from "./landing/Landing";
import Upload from "./upload/Upload";
import Manage from "./manage/Mange";
import Schedule from "./schedule/Schedule";
import Intgrate from "./integrate/Integrate";
import Usecase from "./usecase/Usecase";
import SignUp from "./signup/Signup";
import '../../App.css'

export default function index() {
  return (
    <div>
      <div className="background-dots-one" />
      {/* <div className="background-dots-two" /> */}
      <Landing />
      <Upload />
      <Manage />
      <Schedule />
      <Intgrate />
      <Usecase />
      <SignUp /> 
    </div>
  );
}
