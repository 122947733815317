import React from "react";
import "../payment.css";
import Grid from "@material-ui/core/Grid";

export default function Trial() {
  return (
    <div
      className="payment-box payment-box-padding  " 
    >
      <Grid container>
        
        
        <Grid item md={12} sm={12} lg={12} xs={12} >
          <div style={{ padding: "10px 0px" }}>
            <p className="card-heading">Premium</p>
          </div>
        </Grid>

        

        <Grid item  md={12} sm={12} lg={12} xs={12}>
              <div style={{ padding: "10px 0px" }}>
                <p className="card-body">
                  Start from single screen and scale when ever you needs
                </p>
              </div>
        </Grid>

        <Grid item  md={12} sm={12} lg={12} xs={12}>
          <div style={{ padding: "10px 0px" }}>
            <p className="card-subtitle">
              50 SCREEN
            </p>
          </div>
        </Grid>

        <Grid item md={12} sm={12} lg={12} xs={12}>
                <div style={{ padding: "10px 0px" }}>
                  <p className="card-title" >Pricing</p>
                  <p className="card-heading" > $479 </p>
                </div>
              </Grid>

              
       
        <Grid item  md={12} sm={12} lg={12} xs={12}>
          <div style={{ padding: "20px 0px" }}>
            <button className="button-primary">Get Started</button>
          </div>
        </Grid>
        <Grid item  md={12} sm={12} lg={12} xs={12}>
              <div style={{ padding: "10px 0px" }}>
                <p className="card-body-small">
                  Start from single screen and scale when ever you needs
                </p>
              </div>
        </Grid>

      </Grid>
    </div>
  );
}
