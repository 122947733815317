import React from "react";
import "./payment.css";
import Grid from "@material-ui/core/Grid";


export default function Trial() {
  return (
    <div className="payment-box-gradient"    style={{
        height:'400px',
        justifyContent: "flex-start",
        padding: "0px 10px 0px 60px"
      }}>


           <Grid container >
  

        <Grid item md={12}>
          <Grid container>
            <Grid item md={12} style={{}}>
              <div style={{ padding: "10px 0px", paddingRight:'50px' }}>
                <p className="card-body"  style={{ padding: "5px 0px", color:'#fff'}}>
                Get started by any of these plan and you can easily switch from
                one to another.
                </p>

                <p className="card-body"  style={{ padding: "5px 0px",color:'#fff'}}>
                You can always count on the help of our expert support Team 24h
                / 7d.
                </p>

                <p className="card-body"  style={{ padding: "5px 0px",color:'#fff'}}>
                Get started by any of these plan and you can easily switch from
                one to another.
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>



        <Grid item md={12}>
          
          <div className="card-link" style={{ padding: "10px 2px", color:'#fff' }}>
            Learn More
          </div>
        </Grid>
      </Grid>    
    
    </div>
  );
}
