import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import "./manage.css";
import Hidden from "@material-ui/core/Hidden";

export default class Upload extends Component {
  render() {
    return (
      <Grid container className="container">
        <Hidden mdUp>
          <Grid item md={5}>
            <div
              style={{
                display: "flex",
                padding: "30px",
                alignItems: "center",
                backgroundColor: "#ffffff",
                width: "60vw",
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
              }}
            >
              <div>
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOut="fadeOut"
                  animateOnce={true}
                  delay={200}
                >
                  <p className="heading">Manage.</p>
                </ScrollAnimation>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                padding: "30px 30px",
                alignItems: "center",
                backgroundColor: "#ffffff"
              }}
            >
              <p className="title">
                Make playlists <br />& Manage them
              </p>
            </div>

            <div
              style={{
                display: "flex",
                padding: "30px",
                alignItems: "center",
                backgroundColor: "#ffffff",
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
              }}
            >
              <p className="body">
                Content uploaded to the server can
                <br />
                be arranged accordingly.
                <br />
                Playlists can be made out of uploaded content
              </p>
            </div>
          </Grid>
        </Hidden>

        <Grid
          item
          md={7}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative"
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "auto",
              width: "auto",
              left: "0px",
              top: "20px",
              backgroundColor: "transparent"
            }}
          >
            <img src={require("../../../assets/mac-white.png")} width="90%" />
          </div>

          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            animateOnce={true}
            delay={300}
          >
            <div
            className='palylist-image-one-position'
            >
              <img src={require("../../../assets/palylist.png")}    className='palylist-image-size' />
            </div>{" "}
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            animateOnce={true}
            delay={600}
          >
            <div
            className='palylist-image-two-position'
            >
              <img src={require("../../../assets/palylist.png")} className='palylist-image-size'/>
            </div>{" "}
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            animateOnce={true}
            delay={1000}
          >
            <div
            className='palylist-image-active-position'
            >
              <img
                src={require("../../../assets/playlistactive.png")}
                className='palylist-image-active-size'
              />
            </div>{" "}
          </ScrollAnimation>

          <div style={{ width: "85%", height: "85%" }}>
            <img src={require("../../../assets/TVElement.png")} width="100%" />
          </div>
        </Grid>

        <Hidden smDown>
          <Grid item md={5} style={{ paddingTop: "30px" }}>
            <div
              style={{
                display: "flex",
                padding: "30px",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundColor: "#ffffff",
                // width:'60vw',
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
              }}
            >
              <ScrollAnimation
                animateIn="fadeIn"
                animateOut="fadeOut"
                animateOnce={true}
                delay={200}
              >
                <div>
                  <p className="heading">Manage.</p>
                </div>
              </ScrollAnimation>
            </div>

            <div
              style={{
                display: "flex",
                padding: "30px 30px",
                alignItems: "center",
                backgroundColor: "#ffffff",
                justifyContent: "flex-end"
              }}
            >
              <p className="title" style={{ textAlign: "right" }}>
                Make playlists <br />& Manage them
              </p>
            </div>

            <div
              style={{
                display: "flex",
                padding: "30px",
                paddingLeft: "0px",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundColor: "#ffffff",
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
              }}
            >
              <p className="body" style={{ textAlign: "right" }}>
                Content uploaded to the server can
                <br />
                be arranged accordingly.
                <br />
                Playlists can be made out of uploaded content
              </p>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}
