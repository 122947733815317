import React from "react";
import "./App.css";
import Container from "@material-ui/core/Container";
import Header from "./components/header/Header";
import Body from "./components/body/index";
import Footer from "./components/footer/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Payment from "./components/payment/index";
import Signin from "./components/account/signin/index";
import Signup from "./components/account/signup/index";
import privacyPolicy from "./components/conditions/privacyPolicy";
import termsAndConditions from "./components/conditions/termsAndConditions";
import terms from "./components/conditions/termsAndConditions";

function App() {
  return (
    <Router>
      <div className="background">
       
        {/* <Hidden smDown> */}
        <div className="background-image" />
        {/* </Hidden>  */}

        <Header /> 
        <Container
          maxWidth="lg"
          style={{ backgroundColor: "#fff", padding: "0px" }}
        >
          <Switch>
            <Route path="/" exact component={Body} />
            <Route path="/payment" exact component={Payment} />
            <Route path="/signin" exact component={Signin} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/privacy" exact component={terms} />
            <Route path="/terms" exact component={privacyPolicy} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
