import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import Transition from "react-transition-group/Transition";
import './schedule.css'

export default function Schedule() {
  const [upload, setUpload] = useState(true);
  const [schedule, setSchedule] = useState(true);
  const [scheduleddate, setscheduleddate] = useState(false);

  return (
    <Grid
      container
     className='container'
    >
      <Grid item md={5}>
        <div
          style={{
            display: "flex",
            padding: "40px",
            alignItems: "center",
            backgroundColor: "#ffffff",
            width: "60vw",
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
          }}
        >
          <div>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              animateOnce={true}
              delay={200}
            >
              <p className="heading">Schedule.</p>
            </ScrollAnimation>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            padding: "40px 40px",
            alignItems: "center",
            backgroundColor: "#ffffff"
          }}
        >
          <p className="title">
            Schedule your content as per
            <br />
            requirement.
          </p>
        </div>

        <div
          style={{
            display: "flex",
            padding: "40px",
            alignItems: "center",
            backgroundColor: "#ffffff",
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
          }}
        >
          <p className="body">
            No more hassles changing playlists time to
            <br />
            time. Start scheduling it. Pick a day & time.
          </p>
        </div>
      </Grid>

      <Grid
        item
        md={7}
        style={{
          zIndex: "9999",
          display: "flex",
          justifyContent: "flex-end",
          // alignItems: "center",
          position: "relative",
          backgroundColor:'transparent'
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "auto",
            width: "auto",
            left: "15%",
            top: "0px",
            backgroundColor: "transparent"
          }}
        >
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            animateOnce={true}
            delay={50}
            afterAnimatedIn={() => setUpload(false)}
          >
            {upload ? (
              <img
                src={require("../../../assets/mac-white.png")}
                width="100%"
              />
            ) : (
              <img src={require("../../../assets/schedule.png")} width="100%" />
            )}
          </ScrollAnimation>
        </div>

        <div
          className='date-image-position'
        >
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            animateOnce={true}
            delay={2000}
            afterAnimatedIn={() => setSchedule(false)}
          >
            
                <div>
                  <img
                    style={{
                      // transition: "opacity 1s ease-out",
                      // opacity: state === "exiting" ? 0 : 1
                    }}
                    src={require("../../../assets/scheduledate.png")}
                    className='date-image-size'
                  />
                </div>
          

          
          </ScrollAnimation>
        </div>
        
        {/* .........scheduleselect */}

        <div
        className='shedule-image-position'
        >
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            animateOnce={false}
            delay={600}
            afterAnimatedIn={() =>
              setTimeout(() => {
                setSchedule(false);
              }, 500)
            }
          >
            {/* <Transition in={schedule} timeout={500} mountOnEnter unmountOnExit>
              {state => (
                <div
                  className="test"
                  style={{
                    transition: "opacity .5s ease-out",
                    opacity: state === "exiting" ? 0 : 1
                  }}
                > */}
                  <img
                    src={require("../../../assets/scheduleselect.png")}
                   className='schedule-image-size'
                  />
                {/* </div>
              )}
            </Transition> */}
          </ScrollAnimation>
        </div>


        <div style={{ width: "95%", height: "95%" }}>
          <img src={require("../../../assets/TVElement.png")} width="80%" />
        </div>
      </Grid>
    </Grid>
  );
}
