import React from "react";
import Grid from "@material-ui/core/Grid";
import Signup from './Signup'
import "animate.css/animate.min.css";

export default function landing() {
  return (
    <Grid
      container
      style={{ padding: "30px", paddingBottom: "80px",minHeight:'100vh' }}
    >
      <Grid lg={7} md={7} sm={12} xs={12} style={{ paddingTop: "80px", }}>
        <div
          style={{
            display: "flex",
            padding: "40px",
            alignItems: "center",
            width: "60vw",
            backgroundColor: "transparent",
            paddingBottom: "0px"
            // backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
          }}
        >
          <div style={{ zIndex: "999" }}>
            <p className="heading">
              Welcome 
            </p>
           

            <p className="title" style={{padding:'30px 0px'}}>
           
           14 Day free trial<br />
           No creditcard required
            </p>

            <p className="body" style={{padding:'30px 0px'}}>
            Simple and powerful way to manage and publish<br /> content on your displays.
            </p>

          </div>
        
        </div>
      </Grid>


      <Grid item  lg={5} md={5} sm={12} xs={12}  className='signin-box-padding'>
        <Signup />
      </Grid>



    </Grid>
  );
}
