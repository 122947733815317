import React from "react";
import Grid from "@material-ui/core/Grid";
import Signin from './Signin'
import {Link} from 'react-router-dom'
import './signin.css'
import "animate.css/animate.min.css";

export default function landing() {
  return (
    <Grid
      container
      style={{ padding: "30px", paddingBottom: "80px",minHeight:'100vh' }}
    >
      <Grid item lg={7} md={7} sm={12} xs={12} style={{ paddingTop: "80px", }}>
        <div
          style={{
            display: "flex",
            padding: "40px",
            alignItems: "center",
            width: "60vw",
            backgroundColor: "transparent",
            paddingBottom: "0px"
          }}
        >
          <div style={{ zIndex: "999" }}>
            <p className="heading">
             Your Digital 
             <br />signage 
              needs  an<br />  update. ?
            </p>
            <p className="title" style={{padding:'30px 0px'}}>
             ViewBox makes it easy for you.
            </p>
          
          <Link to='/signup'>
            <button className='button-primary'>
                  Signup Now
              </button>
              </Link>
          
          </div>
        
        </div>
      </Grid> 


      <Grid item  lg={5} md={5} sm={12} xs={12}  className='signin-box-padding'>
        <Signin />
      </Grid> 



    </Grid>
  );
}
